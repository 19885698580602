var columns = [{
  title: "用户UID",
  dataIndex: "userid",
  key: "userid",
  width: "20%",
  scopedSlots: {
    customRender: "userid"
  }
}, {
  title: "认证提交时间",
  dataIndex: "createtime",
  key: "createtime",
  width: "20%",
  scopedSlots: {
    customRender: "createtime"
  }
}, {
  title: "昵称",
  dataIndex: "username",
  key: "username",
  width: "20%",
  scopedSlots: {
    customRender: "username"
  }
}, {
  title: "用户等级",
  dataIndex: "usergrade",
  key: "usergrade",
  width: "10%",
  scopedSlots: {
    customRender: "usergrade"
  }
}, {
  title: "认证类型",
  dataIndex: "certification",
  key: "certification",
  width: "10%",
  scopedSlots: {
    customRender: "certification"
  }
}, {
  title: '状态',
  dataIndex: 'checkstatus',
  key: "checkstatus",
  width: "10%",
  scopedSlots: {
    customRender: "checkstatus"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  // fixed: "right",
  width: 150,
  scopedSlots: {
    customRender: "action"
  }
}];
var logColumns = [{
  title: "创建时间",
  dataIndex: "createtime",
  key: "createtime",
  width: "30%",
  scopedSlots: {
    customRender: "createtime"
  }
}, {
  title: "内容",
  dataIndex: "memo",
  key: "memo",
  width: "70%",
  scopedSlots: {
    customRender: "memo"
  }
}];
export { columns, logColumns };